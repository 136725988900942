module.exports = {
    custom: {
        language: true,
        date: {
            startDate: true,
            endDate: true
        },
        gps: true,
        tags: true,
        favorite: true,
        price: true,
        promoPrice: true,
        quantity: true,
        rating: true,
        order: true,
        contacts: {
            email: true,
            phone: true,
            website: true,
            link: true,
            pdf: false
        },
        image: true,
        gallery: true,
    },
    points_of_interest: {
        language: true,
        date: {
            startDate: false,
            endDate: false
        },
        gps: true,
        tags: true,
        favorite: true,
        price: false,
        promoPrice: false,
        quantity: false,
        rating: true,
        order: true,
        contacts: {
            email: true,
            phone: true,
            website: true,
            link: true,
            pdf: false
        },
        image: true,
        gallery: true,
    },
    events: {
        language: true,
        date: {
            startDate: true,
            endDate: true
        },
        gps: true,
        tags: true,
        favorite: true,
        price: true,
        promoPrice: true,
        quantity: true,
        rating: true,
        order: true,
        contacts: {
            email: true,
            phone: true,
            website: true,
            link: true,
            pdf: false
        },
        image: true,
        gallery: true,
    },
    news: {
        language: true,
        date: {
            startDate: true,
            endDate: false
        },
        gps: false,
        tags: true,
        favorite: true,
        price: false,
        promoPrice: false,
        quantity: false,
        rating: false,
        order: true,
        contacts: {
            email: false,
            phone: false,
            website: false,
            link: true,
            pdf: true
        },
        image: true,
        gallery: true,
    }
}



