export function FontsController() {

    let events = new andy.EventManager();
    let styleEl = document.createElement('style');
    let appendedFonts = {};

    function constructor() {
        styleEl.type = 'text/css';
        document.head.appendChild(styleEl);

    }

    function getFontsAsArray() {
        return Object.values(andy.backend.services.fonts.dataset.data);
    }

    function getFonts() {
        return andy.backend.services.fonts.dataset.data;
    }

    function renderFontsCSS() {
        styleEl.innerHTML = Object.values(appendedFonts).map(font => { return generateImportCSS(font); }).join('\n');
    }

    function generateImportCSS(meta) {
        return [
            `@font-face {`,
            `font-family: "${meta.fontFamily}";`,
            `font-style: ${(meta.fontSubfamily) ? meta.fontSubfamily.toLowerCase() : ''};`,
            `src: url(${andy.backend.hostname + (meta.isUserContent) ? "/data/" : "/fonts/" + meta.filename});`,
            `}`
        ].join('\n')
    }

    function appendFont(id) {
        if (!appendedFonts[id]) {
            let font = andy.backend.services.fonts.dataset.data[id];
            appendedFonts[id] = font;
            renderFontsCSS();
        }
    }

    function appendByFontFamily(fontFamily) {
        Object.values(andy.backend.services.fonts.dataset.data).forEach(font => {
            if (font.meta.fontFamily === fontFamily) {
                appendFont(font.meta.ID);
            }
        })
    }

    constructor();

    return {
        getFonts,
        getFontsAsArray,
        appendByFontFamily,
        appendFont,
        on: events.addEventListener
    }

}