require('../../env/env');
require('../../dev/assets/framework/framework');
//require('./../../../../../framework/andy-framework/dev/framework');
require('./framework/extend/extend');
window.SocketIOFileUpload = require('socketio-file-upload');

import { AndyFrontApp } from './js/app';

let loaderRemoved = false; 

function removeLoader(){
    if(!loaderRemoved){
        loaderRemoved = true; 
        let loadingEl = document.getElementById('andy-app-loading-spinner');
        loadingEl.classList.add('hide');
        setTimeout(()=>{loadingEl.remove()}, 1000);
    }
}

window.onload = function () {

    let parentEl = document.getElementById('andy-app');

    let app = new AndyFrontApp();

    app.on('initialized', () => {
        removeLoader(); 
    });

    app.on('login.required', () => {
        removeLoader(); 
    });

    app.render(parentEl);
    app.start();

};