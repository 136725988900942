export function CollectionFormModal (AudioTabs, VideoTabs, GalleryTabs) {
    let audioTabs = new AudioTabs();
    let videoTabs = new VideoTabs();
    let galleryTabs = new GalleryTabs();

    let events = new andy.EventManager();

    let collectionForm = new andy.UI.Form('data', {
        id: {
            label: 'import_id', 
            item: new andy.UI.Input({}),
            description: 'If this item was imported, this is the ID present on the spreadsheet and can\'t be changed'
        },
        language: {
            label: 'language',
            item: new andy.UI.LanguageObject({
                name: {
                    label: 'name',
                    item: new andy.UI.Input({})
                },
                title: {
                    label: 'title',
                    item: new andy.UI.Input({})
                },
                shortDescription: {
                    label: 'short_description',
                    item: new andy.UI.Input({})
                },
                longDescription: {
                    label: 'long_description',
                    item: new andy.UI.Input({})
                },
                extraDescription: {
                    label: 'extra_description',
                    item: new andy.UI.Input({})
                }
            })
        },
        color: {
            label: 'color',
            item: new andy.UI.ColorPicker({}),
        },
        date: {
            label: 'date_fields',
            item: new andy.UI.Form('dates', {
                startDate: {
                    label: 'start_date',
                    item: new andy.UI.DatePicker(null, {
                        enableTime: true,
                        dateFormat: "Y-m-d H:i"
                    })
                },
                endDate: {
                    label: 'end_date',
                    item: new andy.UI.DatePicker(null, {
                        enableTime: true,
                        dateFormat: "Y-m-d H:i"
                    })
                }
            })
        },
        gps: {
            label: 'gps_coordinates',
            item: new andy.UI.Form('latlng', {
                lat: {
                    label: 'lat',
                    item: new andy.UI.Input({})
                },
                lng: {
                    label: 'lng',
                    item: new andy.UI.Input({})
                }
            })
        },
        contacts: {
            label: 'contact_fields',
            item: new andy.UI.Form('contacts', {
                email: {
                    label: 'email',
                    item: new andy.UI.Input({})
                },
                phone: {
                    label: 'phone',
                    item: new andy.UI.Input({ type: 'number' })
                },
                website: {
                    label: 'website',
                    item: new andy.UI.Input({})
                },
                link: {
                    label: 'link',
                    item: new andy.UI.Input({})
                },
                pdf: {
                    label: 'pdf',
                    item: new andy.UI.GFMSelect({ type: 'application', extension: 'pdf' })
                }
            })
        },
        price: {
            label: 'price',
            item: new andy.UI.Input({ type: 'number' }),
            description: 'In cents'
        },
        promoPrice: {
            label: 'promo_price',
            item: new andy.UI.Input({ type: 'number' }),
            description: 'In cents'
        },
        quantity: {
            label: 'quantity',
            item: new andy.UI.Input({ type: 'number' })
        },
        rating: {
            label: 'rating',
            item: new andy.UI.Input({ type: 'number', min: 0, max: 10 }),
            description: 'Between 1 and 10'
        },
        order: {
            label: 'order',
            item: new andy.UI.Input({ type: 'number' })
        },
        icon: {
            label: 'icon',
            item: new andy.UI.GFMSelect({ type: 'image' })
        },
        image: {
            label: 'image',
            item: new andy.UI.GFMSelect({ type: 'image' })
        },
        gallery: {
            label: 'gallery',
            item: galleryTabs
        },
        video: {
            label: 'video',
            item: videoTabs
        },
        audio: {
            label: 'audio',
            item: audioTabs
        },
        favorite: {
            label: 'favorite',
            item: new andy.UI.Switch(false)
        },
        tags: {
            label: 'tags', 
            item: new andy.UI.TagSelector({})
        }
    }, {
        save: new andy.UI.Button({ label: 'save', icon: 'save' }, savePageHandler)
    });

    if (collectionForm.items.language) {
        collectionForm.items.language.item.getElement().querySelector('.header').style.display = 'none';
        collectionForm.items.language.item.getElement().querySelector('.footer').style.display = 'none';
        collectionForm.items.language.item.setValue({
            en_US: {},
            pt_PT: {}
        });
    }

    if (collectionForm.items.id) {
        collectionForm.items.id.item.getElement().setAttribute('disabled', true);
    }

    function savePageHandler(e) {
        let values = map(collectionForm.getValue());
        
        if (!validate(values)) {
            return;
        }

        events.dispatchEvent('collection.item.save', { values });
    }

    function validate(values) { // to-do: manual validation of values
        console.log(!isNaN(values.rating));
        if (!isNaN(values.rating)) {
            if (values.rating < 0 || values.rating > 5) {
                andy.db.toast('invalid_rating', 'error');
                return false;
            }
        }

        return true;
    }

    function map(values) {
        if (andy.db.vars.selectedCollectionItem) {
            values._id = andy.db.vars.selectedCollectionItem._id;
        }

        return values;
    }

    function init(currentLevel = collectionForm.items, match = andy.db.vars.selectedDatabase.fields) {
        for (let key in currentLevel) {
            let item = currentLevel[key];
            if (typeof match[key] === 'object') {
                init(currentLevel[key].item.items, match[key]);
                nextLevelCheck(match[key], item);
            }
            else if (match[key] === false) {
                item.item.getElement().parentElement.parentElement.style.display = 'none';
                item.label.getElement().parentElement.parentElement.style.display = 'none';
            }
            else if (match[key] === true) {
                item.item.getElement().parentElement.parentElement.style.display = 'inherit';
                item.label.getElement().parentElement.parentElement.style.display = 'inherit';
            }
        }
    }

    function nextLevelCheck(a, b) {
        let show = false;
        for (let s in a) {
            if (a[s]) {
                show = true;
            }
        }
        if (!show) {
            b.item.getElement().parentElement.parentElement.style.display = 'none';
            b.label.getElement().parentElement.parentElement.style.display = 'none';
        }
        else {
            b.item.getElement().parentElement.parentElement.style.display = 'inherit';
            b.label.getElement().parentElement.parentElement.style.display = 'inherit';
        }
    }


    let collectionModal  = new andy.UI.Modal(collectionForm);
    collectionModal.getElement().style.width = '80%';
    
    return {
        modal: collectionModal,
        form: collectionForm,
        init,
        on: events.addEventListener
    };
}