export function dictionaryPT_PT() {
    return {
        "pt_PT": "Português (PT)",
        "en_US": "English (US)",
        "es_CO": "Español (CO)",
        "devices": "Equipamentos",
        "layouts": "Layouts",
        "applications": "Aplicações",
        "modules": "Módulos",
        "statistics": "Estatísticas",
        "system": "Sistema",
        "admin": "Admin",
        "new_layout": "Novo Layout",
        "layout_name": "Nome do Layout",
        "layout_description": "Descrição do Layout",
        "width": "Largura",
        "height": "Altura",
        "create": "Criar",
        "edit_area": "Editar Area",
        "area_name": "Nome da Area",
        "area_description": "Descrição da Area",
        "top": "Topo",
        "left": "Esquerda",
        "save": "Guardar",
        "assign_to_module": "Associar ao Module",
        "cancel": "Cancelar",
        "save_changes": "Guardar Alterações",
        "save_confirm": "Quer mesmo guardar as alterações do projeto?",
        "device_properties": "Propriedades do Equipamento",
        "name": "Nome",
        "surname": "Sobrenome",
        "description": "Descrição",
        "search-medias": "Procurar Multimédia ...",
        "search-playlists": "Procurar Playlists ...",
        "search-devices": "Procurar Equipamentos ...",
        "search-layouts": "Procurar Layouts ...",
        "search-modules": "Procurar Módulos ...",
        "preview": "Preview",
        "enabled": "Ativo",
        "play_duration": "Duração (Seconds)",
        "tags": "Tags",
        "upload_media": "Upload Ficheiro Multimédia",
        "new_playlist": "Nova Playlist",
        "add": "Adicionar",
        "location": 'Local',
        "country": 'Pais',
        "city": "Cidade",
        "latitude": "Latitude",
        "longitude": "Longitude",
        "zipcode": "Código Postal",
        "user": "Utilizador",
        "user_profile": "Perfil de Utilizador",
        "user_location": "Localização do Utilizador",
        "user_contacts": "Contacto do Utilizador",
        "user_credentials": "Credenciais do Utilizador",
        "organization": "Organização",
        "department": "Departamento",
        "language": "Idioma",
        "email": "E-Mail",
        "phone": "Telefone",
        "mobile": "Telemovel",
        "username": "Username",
        "old_password": "Password Antiga",
        "new_password": "Nova Password",
        "repeat_password": "Repetir nova Password",
        "password": "Password",
        "cpu_info": "Processador",
        "memory_info": "Memoria",
        "os_info": "Sistema Operativo",
        "status": "Estado",
        "displays": "Ecrãs",
        "inputs": "Periférico",
        "network": "Rede",
        "settings": "Configurações",
        "users": "Utilizadores",
        "roles": "Funções",
        "role": "Função",
        "keys": "Keys",
        "languages": "Idiomas",
        "general": "Geral",
        "platform": "Plataforma",
        "search_users": "Procurar Utilizadores",
        "new_user": "Novo Utilizador",
        "user_registry_policy": "Politica de Registo de Utilizadores",
        "allow_new_users": "Permitir auto registo",
        "activate_domain_whitelist": "Ativar whitelist de dominios",
        "allowed_domains": "Permitir Dominios",
        "max_users": "Maximo de registo de Utilizadores",
        "default_language": "Idioma por defeito",
        "active_languages": "Idiomas ativos",
        "search_roles": "Procurar Funções",
        "new_role": "Nova Função",
        "role_permissions": "Permissões",
        "terminal": "Terminal",
        "background_color": "Cor de Fundo",
        "background_image": "Imagem de Fundo",
        "properties": "Propriedades",
        "object_tree": "Objetos",
        "tile_size": "Tile Size",
        "snap_to_grid": "Snap objects to grid",
        "set_grid": "Ativar Grelha",
        "memory_usage": "Uso de Memoria",
        "remote_terminal": "Terminal Remoto",
        "assign_playlist": "Associar Playlist",
        "order": "Ordem",
        "color": "Cor",
        "module": "Módulo",
        "search": "Procurar...",
        "clock": "Relógio",
        "timezone": "Fuso Horário",
        "format_12": "Formato 12h",
        "inside_padding": "Preenchimento Interior",
        "text_color": "Cor de texto",
        "done": "Feito",
        "date": "Data",
        "feed": "Feed",
        "image": "Imagem",
        "social_wall": "Social Wall",
        "signage": "Signage",
        "webview": "Web Browser",
        "weather": "Weather",
        "webview_options": "Opções de Web Browser",
        "node_api": "Low Level API",
        "plugins": "Plugins",
        "home_button": "Botão Home",
        "back_button": "Botão Back",
        "forward_button": "Botão Forward",
        "refresh_button": "Botão Reload",
        "url": "URL",
        "url_input": "Caixa de inserção de URL",
        "weather_widget": "Ferramenta de Weather",
        "city_id": "Cidade",
        "api_key": "API KEY",
        "refresh_rate": "Taxa de atualização",
        "theme": "Tema",
        "displays_and_resolutions": "Ecrãs e resoluções",
        "button": "Botão",
        "fonts": "Tipo de Letra",
        "keyboard": "Teclado",
        "position": "Posição",
        "size": "Tamanho",
        "repeat": "Repetir",
        "actions": "Ações",
        "click": "ao Clicar",
        "local_data_servers": "Servidores de dados locais",
        "strict_mode": "Modo restrito",
        "hosts": "Hosts",
        "generate_token": "Gerar Token",
        "display_configuration": "Configurar Ecrã",
        "enable": "Ativar",
        "rotation": "Rotação",
        "position_top": "Posição Topo",
        "position_left": "Posição Esquerda",
        "mode": "Modo",
        "deregistration_confirm": "Confirmar Deregisto de Equipamento",
        "proceed": "Proceder",
        "deregister_direct_msg": "O Deregisto removerá as configurações do equipamento e a conexão remota. Deseja continuar?",
        "deregister_undirect_msg": "Este equipamento parece estar off-line. Apenas irá remover o equipamento na plataforma. Deseja continuar?",
        "deregister": "Deregisto",
        "registration": "Registar",
        "audio_mute": "Silenciar o áudio do Browser",
        "new_application": "Nova Aplicação",
        "search_applications": "Procurar Aplicação...",
        "moduledata": "Dados",
        "key": "Key",
        "layout_saved_error": "Erro a guardar Layout!",
        "layout_saved_success": "Layout guardado com sucesso",
        "layout_removed_success": "Layout removido com sucesso",
        "layout_removed_error": "Erro a remover Layout",
        "device_start_direct_mode": "Iniciou uma ligação em direto com o equipamento",
        "device_end_direct_mode": "Terminou a ligação em direto com o equipamento",
        "device_connected_info": "Um Equipamento foi conectado à plataforma",
        "device_deregister_success": "Equipamento deregistado com sucesso",
        "device_deregister_error": "Erro a deregistar equipamento",
        "device_deregister_info": "Um equipamento foi deregistado",
        "user_saved_success": "Utilizador guardado com sucesso",
        "button_style": "Estilo do botão",
        "local_data_server": "Servidor de dados local",
        "device_settings": "Definições do equipamento",
        "add_media_files": "Adicionar ficheiro de multimédia",
        "live_screen": "Ecrã em direto",
        "quizzes": "Questionários",
        "questions": "Questões",
        "new_quiz": "Novo Questionários",
        "new_question": "Nova Questão",
        "search_quizzes": "Procurar Questionários",
        "search_questions": "Procurar Questões",
        "quiz": "Questionário",
        "randomize": "Aleatório",
        "change_credentials": "Alterar Credenciais",
        "openweathermap": "OpenWeatherMap.org Widget",
        "type": "Tipo",
        "question": "Questão",
        "answers": "Resposta",
        "info": "Informação",
        "device_disconnected_info": "Um Equipamento foi desconectado.",
        "lock": "Bloquear",
        "login": "Entrar",
        "recover_password": "Recuperar Password",
        "true_or_false": "Verdadeiro ou Falso",
        "multiple_choice": "Escolha Múltipla",
        "false": "False",
        "true": "Verdadeiro",
        "correct": "Resposta Correta",
        "text": "Texto",
        "answer": "Resposta",
        "add_questions": "Adicionar Questões",
        "number_of_questions": "Numero de Questões",
        "application_settings": "Definições da Aplicação ",
        "enable_override": "Ativar Sobrepor",
        "device_terminal": "Terminal",
        "device_power": "Desligar",
        "device_reboot": "Reiniciar",
        "device_displays": "Ecrãs",
        "device_inputs": "Periféricos",
        "device_network": "Rede",
        "users_create": "Criar Utilizadores",
        "users_delete": "Apagar Utilizadores",
        "users_credentials": "Credenciais de Utilizador",
        "application_edit": "Editar",
        "application_create": "Criar",
        "application_delete": "Remover",
        "layout_edit": "Editar",
        "layout_create": "Criar",
        "layout_delete": "Remover",
        "module_edit": "Editar",
        "statistic_view": "Ver",
        "administrator": "Administrador",
        "media_saved_success": "Ficheiro de multimédia guardado com sucesso.",
        "shuffle": "Baralhar",
        "font_size": "Tamanho de letra",
        "contain": "Conter",
        "no_repeat": "Não Repetir",
        "cover": "Cobrir",
        "center": "Centrado",
        "right": "Direita",
        "bottom": "Inferior",
        "user_removed_success": "Utilizador removido com sucesso.",
        "device_saved_success": "Equipamento guardado com sucesso",
        "sessions_per_device": "Sessões por Equipamento",
        "interactions_per_device": "Interações por Equipamento",
        "time_per_device": "Tempo por Equipamento",
        "online_devices": "Equipamentos online",
        "dashboard": "Painel de Controlo",
        "device_applications": "Aplicações do equipamento",
        "override": "Sobrepor",
        "environment_variables": "Variaveis de Ambiente",
        "executable_command": "Comandos para executar no inicio",
        "smtp_email_configuration": "Configuração de email SMTP",
        "host": "Host",
        "port": "Porta",
        "ssl": "SSL",
        "tls": "TLS",
        "model": "Modelo",
        "cores": "Núcleos",
        "boxShadow": "Sombra",
        "textColor": "Cor do Texto",
        "backgroundColor": "Cor de Fundo",
        "border-radius": "Raio da borda",
        "shutdown_info": "Informação de Desligar",
        "hour": "Hora",
        "invert": "Inverter",
        "mute": "Silencioso",
        "statistics_view": "Ver Estatisticas",
        "timeline": "Timeline",
        "watercycle": "Ciclos da Água",
        "words": "Palavras",
        "cards": "Cartas",
        "tank": "Reservatórios",
        "new_event": "Novo Evento",
        "menu": "Menu",
        "new_menu": "Novo Menu",
        "parent": "Pai",
        "upload_icon": "Envio de Icon",
        "upload_file": "Envio de Ficheiro",
        "change": "Alterar",
        "show_all": "Mostrar Todos",
        "edit": "Editar",
        "clear": "Apagar",
        "form": "Formulário",
        "new_form": "Novo Formulário",
        "fields": "Campos",
        "code": "Código",
        "icon": "Icon",
        "click_to_upload": "Clica para iniciar Upload",
        "click_or_drop_to_upload": "Clica ou Larga um ficheiro para iniciar upload",
        "value": "Valor",
        "file": "Ficheiro",
        "label": "Etiqueta",
        "assign_applications": "Associar Aplicações",
        "platform_languages": "Idioma da Plataforma",
        "content_languages": "Idiomas do Conteudo",
        "sound": "Som",
        "network_info": "Informação de Rede",
        "player_info": "Informação do player",
        "volume": "Volume",
        "player_upgrade": "Upgrade",
        "version": "Versão",
        "deviceLang_saved_success": "Linguagem guardada com sucesso",
        "application_saved_success": "Aplicação guardada com sucesso",
        "user_role_saved_error": "Erro a guardar Função",
        "user_role_saved_success": "Função guardada com sucesso",
        "font_saved_error": "Erro a guardar Tipo de letra",
        "font_saved_success": "Tipo de letra guardado com sucesso",
        "settings_saved_success": "Preferências guardadas com sucesso",
        "settings_saved_error": "Erro a guardar preferências",
        "font_delete_error": "Erro a eliminar Tipo de letra",
        "font_delete_success": "Tipo de letra eliminado com sucesso",
        "insert_font": "Inserir Tipo de letra",
        "choose_font": "Escolher Tipo de letra",
        "offline_device_notification": "Notificação no caso de equipamentos offline",
        "downtime": "Tempo de inatividade",
        "notification": "Notificação",
        "assign_languages": "Associar Idiomas",
        "add_fonts": "Adicionar Tipo de letra",
        "upload": "Upload",
        "new_device": "Novo Equipamento",
        "insert_code": "Inserir Código",
        "uptime_info": "Informação de Tempo de atividade",
        "uptime": "Tempo de atividade",
        "default_font": "Tipo de letra por defeito",
        "device_requested_error": "Não foi possivel pedir esse equipamento",
        "device_requested_success": "Equipamento pedido, aguarde conecção",
        "device_requested_404": "Código de equipamento não existe",
        "no_files": "Sem Ficheiros",
        "all_files": "Todos os Ficheiros",
        "category_videos": "Videos",
        "category_images": "Imagens",
        "category_audio": "Audio",
        "category_others": "Outros",
        "search-files": "Procurar Ficheiros",
        "new_agenda": "Nova Agenda",
        "scheduler": "Agendamento",
        "select_playlist": "Selecionar Playlist",
        "new-schedule": "Novo Agendamento",
        "all_day": "Todo o Dia",
        "weekly": "Semanalmente",
        "fixed": "Fixo",
        "search-agendas": "Procurar Agenda",
        "new-playlist": "Nova Playlist",
        "medias": "Medias",
        "file_detail": "Detalhes do Ficheiro",
        "media_manager": "Gestor de Multimédia",
        "agenda": "Agenda",
        "date_hour_not_valid": "Data ou Hora Invalidos",
        "topic_remove_success": "Topico removido com sucesso",
        "topic_saved_success": "Topico guardado com sucesso",
        "animal_remove_success": "Animal removido com sucesso",
        "animal_saved_success": "Animal guardado com sucesso",
        "desc_saved_success": "Descrição guardada com sucesso",
        "new_animal": "Novo Animal",
        "title": "Titulo",
        "pt": "PT",
        "eng": "ENG",
        "credits": "Creditos",
        "animals": "Animais",
        "animal": "Animal",
        "child": "Filho",
        "images": "Imagens",
        "aka": "AKA",
        "animal_name": "Nome do Animal",
        "strips": "Tiras",
        "new_strip": "Nova Tira",
        "video": "Video",
        "edit_video": "Editar Video",
        "new_svg": "Novo SVG",
        "point": "Ponto",
        "new_point": "Novo Ponto",
        "new_topic": "Novo Topico",
        "time": "Tempo",
        "point_saved_success": "Ponto guardado com sucesso",
        "point_remove_success": "Ponto removido com sucesso",
        "strip_remove_success": "Tira Removida com sucesso",
        "strip_save_success": "Tira guardada com sucesso",
        "family_remove_success": "Familia removida com sucesso",
        "card_code": "Código do cartão",
        "number": "Numero",
        "region": "Região",
        "zone_overlay": "Sobreposição de zona",
        "species": "Especies",
        "new": "Novo",
        "species_saved_success": "Especie guardada com sucesso",
        "species_remove_success": "Especie removida com sucesso",
        "application_removed_success": "Aplicação removida com sucesso",
        "application_created_success": "Aplicação criada com sucesso",
        "specie": "Especie",
        "start": "Inicio",
        "duration": "duração",
        "strip": "Tira",
        "id": "ID",
        "topic": "Topico",
        "list": "Lista",
        "update_info": "Update Info",
        "audioplaylist_saved_error": "Couldn't Save Audio Playlist",
        "audioplaylist_saved_success": "Audio Playlist Saved",
        "audioplaylist_remove_error": "Couldn't Remove Audio Playlist",
        "audioplaylist_remove_success": "Audio Playlist Saved",
        "playlist_saved_error": "Couldn't Save Playlist",
        "playlist_saved_success": "Playlist Saved",
        "playlist_removed_error": "Couldn't Remove Playlist",
        "playlist_removed_success": "Playlist Removed",
        "rss_reader": "Rss Reader",
        "rss_url": "Rss Url",
        "extra_text": "Extra Text",
        "vert_scrol": "Vertical Scroll",
        "interval": "Interval",
        "gfu_saved_error": "Erro a guardar o Ficheiro",
        "gfu_saved_success": "Ficheiro Guardado",
        "gfu_removed_error": "Erro a remover o ficheiro",
        "gfu_removed_success": "File Removido",
        "wired_connection": "Conecção por cabo",
        "ipstatic": "IP Estático",
        "ip": "IP",
        "netmask": "Netmask",
        "gateway": "Gateway",
        "dns": "DNS",
        "backgroundBlur": "Desfoque de fundo",
        "blur-radius": "Raio de desfoque",
        "mediaManager": "Gestor de Multimédia",
        "mediaManager_View": "Ver",
        "mediaManager_Add": "Adicionar",
        "mediaManager_Delete": "Remover",
        "playlists": "Playlists",
        "grid": "Grelha",
        "snap": "Snap",
        "day": "Dia",
        "days": "Dias",
        "minute": "Minuto",
        "minutes": "Minutos",
        "hour": "Hora",
        "hours": "Horas",
        "seconds": "Segundos",
        "assign_layouts": "Associar Layout",
        "add_event": "Adicionar Evento",
        "event": "Evento",
        "sunday": "Domingo",
        "monday": "Segunda",
        "tuesday": "Terça",
        "wednesday": "Quarta",
        "thursday": "Quinta",
        "friday": "Sexta",
        "saturday": "Sábado",
        "user_saved_credentials_error": "Erro a guardar credenciais",
        "user_saved_credentials_success": "Credenciais guardadas",
        "user_removed_error": "Erro a remover Utilizador",
        "user_removed_success": "Utilizador removido",
        "language_removed_error": "Erro a remover Idioma",
        "language_removed_success": "Idioma removida",
        "application_created_error": "Erro a criar aplicação",
        "application_removed_error": "Erro a remover aplicação",
        "click_to_select": "Selecionar",
        "text_editor": "Editor de Texto",
        "content": "Conteúdos",
        "databases": "Bases de Dados",
        "files": "Ficheiros",
        "map": "Mapa",
        "groups": "Grupos",
        "windowedit": "Modo Janela",

        "unique_code": "Código Único",
        "add_tags": "Adicionar Tags",
        "selected_tags_list": "Lista de Tags Selecionados",
        "download_template": "Download do Modelo",
        "import": "Importar",
        "gradient": "Gradiente",
        "gradient_picker": "Seletor do Gradiente",
        "primary_color": "Cor Primária",
        "secondary_color": "Cor Secundária",
        "angle": "Ângulo",
        "percentage": "Percentagem",
        "apply": "Aplicar",
        "gradient_result": "Resultado do Gradiente",
        "activate_fields": "Ativar/Desativar Campos",
        "date_fields": "Campos de Data",
        "dates": "Datas",
        "contact_fields": "Campos de Contacto",
        "website": "Website",
        "link": "Link",
        "pdf": "PDF",
        "language_fields": "Campos de Idioma",
        "gps_coordinates": "Coordenadas GPS",
        "favorite": "Favorito",
        "price": "Preço",
        "promo_price": "Preço Promocional",
        "quantity": "Quantidade",
        "rating": "Avaliação",
        "gallery": "Galeria",
        "database": "Base de Dados",
        "preload_template": "Pré-Carregar Modelo",
        "contacts": "Contactos",
        "add_database": "Adicionar Base de Dados",
        "latlng": "LatLng",
        "lat": "Lat",
        "lng": "Lng",
        "import_id": "ID Importado",
        "short_description": "Descrição Curta",
        "long_description": "Descrição Longa",
        "extra_description": "Descrição Extra",
        "data": "Dados",
        "back": "Voltar",
        "custom": "Customizado",
        "points_of_interest": "Pontos de Interesse",
        "events": "Eventos",
        "news": "Notícias",
        "projects": "Projectos",
        "catalog": "Catálogo",
        "document": "Documento",
        "daily": "Diariamente",
        "weekly": "Semanalmente",
        "monthly": "Mensalmente",
        "yearly": "Anualmente",
        "no_repeat": "Não Repetir",
        "audio": "Audio",
        "triggers": "Triggers",
        "triggers_list": "Lista de triggers",
        "create_trigger_at_current_time": "Criar trigger no tempo atual",
        "clear_all_triggers": "Limpar todos os triggers",
        "trigger": "Trigger",

        // New
        "studio": "Studio",
        "No group": "Sem grupo",
        "no_group": "Sem grupo",
        "profile": "Perfil",
        "accounts": "Contas",
        "content_apis": "APIs de Conteúdo",
        "google_key": "Chave Google",
        "default": "Por defeito",
        "new_page": "Nova página",
        "device_projects": "Projetos do dispositivo",
        "schedule_shutdown": "Agendar encerramento",
        "schedule_sync": "Agendar sincronização",
        "no_commands_list_placeholder": "Sem comandos",
        "architecture": "Arquitetura",
        "attempt_to_connect": "A tentar ligar",
        "remote_actions": "Ações remotas",
        "add_to_group": "Adicionar ao grupo",
        "resume": "Resumir",
        "pause": "Pausar",
        "sync": "Sincronizar",
        "refresh": "Atualizaar",
        "reboot": "Reiniciar",
        "power_off": "Desligar",
        "master_page": "Página master",
        "master_page_description": "Todos os componentes de uma master page estão sempre presentes no ecrã",
        "page_code_description": "Alguns componentes podem requerer o código de página para executar mudanças de página. O código deve ser único no âmbito projeto.",
        "create_new_database": "Criar nova base de dados",
        "start_date": "Data de início",
        "end_date": "Data de fim",
        "content_edit": "Editar",
        "original_name": "Nome original",
        "uploaded": "Carregado",
        "sequence": "Sequência",
        "without_entries_in_sequence": "Sem entradas na sequência",
        "edit_sequence": "Editar sequência",
        "without_info_to_show": "Sem informações para mostrar",
        "studio_applications": "Aplicações do Studio",
        "project": "Projeto",
        "files_upload": "Carregamento de ficheiros",
        "complete": "Completo",
        "sync_group": "Sincronizar grupo",
        "refresh_group": "Atualizar grupo",
        "reboot_group": "Reiniciar grupo",
        "power_off_group": "Desligar grupo",
        "delete_group": "Remover grupo",
        "sort_by": "Ordernar por:",
        "select_a_group": "Selecionar um grupo",
        "request_device_code_description": "Insira o código de 5 dígitos que aparece por baixo do QR code no monitor do equipamento",
        "delete": "Remover",
    }
}